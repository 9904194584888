<template>
  <v-row class="my-5 mx-13">
    <v-col md="4" sm="12" v-for="item in data" :key="item.name">
      <v-btn
        block
        :outlined="paymentsType != item.name"
        :dark="paymentsType != item.name"
        color="info"
        @click="paymentsType = item.name"
      >
        <v-icon left>{{ item.icon }}</v-icon>
        {{ item.title }}
      </v-btn>
    </v-col>
    <v-col cols="12" class="my-5">
      <blankSlip
        v-if="paymentsType == 'BANK_SLIP'"
        @cancel="cancel()"
        @updated="updated()"
      >
      </blankSlip>
      <creditCardPayment
        v-if="paymentsType == 'CREDIT_CARD'"
        @cancel="cancel()"
        @updated="updated()"
      >
      </creditCardPayment>
    </v-col>
  </v-row>
</template>

<script>
import blankSlip from './changePaymentBlankSlip.vue'
import creditCardPayment from './changePaymentCard.vue'
export default {
  components: {
    blankSlip,
    creditCardPayment,
  },
  data() {
    return {
      paymentsType: 'CREDIT_CARD',
      data: [
        {
          title: 'boleto',
          name: 'BANK_SLIP',
          icon: '$vuetify.icons.bankSlip',
        },
        {
          title: 'cartão de crédito',
          name: 'CREDIT_CARD',
          icon: 'credit_card',
        },
      ],
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    updated() {
      this.$emit('updated')
    },
  },
}
</script>

<style>
</style>
