<template>
  <v-row>
    <v-col md="5" sm="10">
      <div class="form mb-3 d-flex align-center text-center">
        <img :src="imgBoleto" /> Boleto Bancário
      </div>
      <p class="ml-1">
        Programe-se: As faturas do Simplificador vencem no dia 10. Cadastrando a
        opção de boleto, o mesmo será enviado até o dia 06 de cada mês.
      </p>
    </v-col>
    <v-col cols="12">
      <v-row justify="space-between">
        <v-col cols="12">
          <v-btn
            color="grey"
            class="white--text mr-3 mb-2"
            @click="cancel"
            :disabled="loading"
          >
            CANCELAR
          </v-btn>
          <v-btn
            color="success"
            class="white--text mb-2"
            @click="updateToBoleto"
            :loading="loading"
          >
            TROCAR PARA BOLETO
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import imgBoleto from '@src/assets/images/misc/icon-pag-boleto.png'
import { TYPE_BOLETO } from '@/store/modules/paymentMethod'

export default {
  data() {
    return {
      imgBoleto,
      loading: false,
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    async updateToBoleto() {
      this.loading = true
      await this.$store.dispatch('paymentMethod/cahngePaymentMethod', {
        paymentType: TYPE_BOLETO,
      })
      this.$store.commit('snackbarFlashMessage/setSuccessEdition')
      this.loading = false
      this.$emit('updated')
    },
  },
}
</script>
<style>
.form {
  align-items: center;
  height: 83px;
  border: 1px solid #b9b9b8;
  border-radius: 6px;
}
.form i {
  margin-left: 15px;
  font-size: 4em;
}
.form img {
  width: 130px;
  margin-right: 10px;
}
</style>
