<template>
  <form @submit="updateToCreditcard" v-on:submit.prevent ref="form">
    <v-row no-gutters>
      <v-col cols="12" sm="4">
        <v-text-field
          ref="name"
          autocomplete="off"
          label="Nome impresso no cartão"
          v-model="form.name"
          :error="!error.name.valid"
          :error-messages="!error.name.valid ? error.name.errorMassang : ''"
          @input="error.name.valid = true"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="4" class="mb-2">
        <v-text-field
          ref="creditcard"
          autocomplete="off"
          label="Número do Cartão"
          v-model="form.creditcard"
          :error="!error.creditcard.valid"
          :error-messages="
            !error.creditcard.valid ? error.creditcard.errorMassang : ''
          "
          validate-on-blur
          @input="error.creditcard.valid = true"
          v-mask="'#### - #### - #### - ####'"
          :v-masked="true"
        />
        <icons-card :active="brand"></icons-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="1" class="mr-2">
        <v-text-field
          ref="expMonth"
          autocomplete="off"
          label="Validade - Mês"
          v-mask="'##'"
          v-model="form.expMonth"
          :error="!error.expMonth.valid"
          :error-messages="
            !error.expMonth.valid ? error.expMonth.errorMassang : ''
          "
          @input="error.expMonth.valid = true"
        />
      </v-col>
      <v-col cols="12" sm="1">
        <v-text-field
          ref="expYear"
          autocomplete="off"
          label="Validade - Ano"
          v-mask="'##'"
          v-model="form.expYear"
          validate-on-blur
          :error="!error.expYear.valid"
          :error-messages="
            !error.expYear.valid ? error.expYear.errorMassang : ''
          "
          @input="error.expYear.valid = true"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="1">
        <v-text-field
          ref="cvv"
          autocomplete="off"
          label="CVV"
          v-model="form.cvv"
          :error="!error.cvv.valid"
          :error-messages="!error.cvv.valid ? error.cvv.errorMassang : ''"
          pattern="[0-9]{3,4}"
          v-mask="'####'"
          @input="error.cvv.valid = true"
        />
      </v-col>
      <v-col cols="12">
        <v-row justify="space-between">
          <v-col cols="12">
            <v-btn
              color="grey"
              class="white--text mr-3 mb-2"
              @click="cancel()"
              :disabled="loading"
            >
              CANCELAR
            </v-btn>
            <v-btn
              color="success"
              class="white--text mb-2"
              type="submit"
              :loading="loading"
            >
              TROCAR PARA CartÃO
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </form>
</template>
<script>
import creditcard from '@src/services/creditcard'
import iconsCard from '@components/widget/iconsCard.vue'
import { TYPE_CREDITCARD } from '@/store/modules/paymentMethod'
export default {
  name: 'payment-creditcard',
  components: {
    iconsCard,
  },
  data() {
    return {
      loading: false,
      error: {
        name: {
          valid: true,
          errorMassang: 'Preencha corretamente',
        },
        creditcard: {
          valid: true,
          errorMassang: 'Preencha corretamente',
        },
        cvv: {
          valid: true,
          errorMassang: 'Preencha corretamente',
        },
        expMonth: {
          valid: true,
          errorMassang: 'Preencha corretamente',
        },
        expYear: {
          valid: true,
          errorMassang: 'Preencha corretamente',
        },
      },
      form: {
        name: '',
        creditcard: '',
        cvv: '',
        expMonth: '',
        expYear: '',
        firstName: '',
        lastName: '',
      },
    }
  },
  computed: {
    brand() {
      if (this.form.creditcard.length > 4) {
        return this.getBrandByCreditCardNumber(this.form.creditcard)
      }
      return null
    },
    firstName() {
      return this.form.name.split(' ')[0]
    },
    lastName() {
      if (!this.form.name) return ''
      let match = /^(\w*)([\s*\w*]*)/.exec(this.form.name)
      return match[match.length - 1].trimStart()
    },
  },
  methods: {
    ...creditcard,
    creditcardIsValid() {
      this.error.creditcard.valid = this.validateNumber(this.form.creditcard)
      this.error.cvv.valid = this.validateCvv(
        this.form.creditcard,
        this.form.cvv
      )
      this.error.expYear.valid = this.validateExpirationByMonthAndYear({
        month: this.form.expMonth,
        year: this.form.expYear,
      })
      this.error.expMonth.valid = this.validateExpirationByMonthAndYear({
        month: this.form.expMonth,
        year: this.form.expYear,
      })
      this.error.name.valid = !!this.form.name
      return (
        this.error.creditcard.valid &&
        this.error.cvv.valid &&
        this.error.expYear.valid &&
        this.error.expMonth.valid &&
        this.error.name.valid
      )
    },
    setErrorCardAll() {
      this.error.creditcard.valid = false
      this.error.cvv.valid = false
      this.error.expYear.valid = false
      this.error.expMonth.valid = false
      this.error.name.valid = false
    },
    async updateToCreditcard(event) {
      event.preventDefault()
      this.loading = true
      if (!this.creditcardIsValid()) {
        this.loading = false
        return
      }
      const objCardIugu = this.objectCreditCard({
        number: this.form.creditcard,
        month: this.form.expMonth,
        year: this.form.expYear,
        firstName: this.firstName,
        lastName: this.lastName,
        cvv: this.form.cvv,
      })
      let iuguToken = await this.createCreditcardToken(objCardIugu)
      if (iuguToken.errors) {
        this.loading = false
        this.setErrorCardAll()
        return
      }
      await this.$store.dispatch('paymentMethod/cahngePaymentMethod', {
        paymentType: TYPE_CREDITCARD,
        tokenIugu: iuguToken.id,
        creditCardLastNumbers: iuguToken.extra_info.display_number,
      })
      this.$store.commit('snackbarFlashMessage/setSuccessEdition')
      this.loading = false
      this.$emit('updated')
    },
    cancel() {
      this.form = {
        name: '',
        creditcard: '',
        cvv: '',
        expMonth: '',
        expYear: '',
        firstName: '',
        lastName: '',
      }
      this.$emit('cancel')
    },
  },
}
</script>
<style module lang="scss">
@import '@design';
</style>
