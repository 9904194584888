<template>
  <v-row>
    <v-subheader>Minha Conta > Forma de Pagamento</v-subheader>
    <v-card width="100%">
      <base-header-information
        :title="headerTitle"
        :description="currentHeaderDescription"
      />
      <div
        v-if="loading"
        class="d-flex justify-center align-center text-center"
      >
        <v-progress-circular indeterminate />
      </div>
      <currentPayment v-if="!loading && !changePaymentMethod">
        <v-btn
          color="orange darken-3"
          class="white--text"
          @click="changePaymentMethod = !changePaymentMethod"
        >
          ALTERAR FORMA DE PAGAMENTO
        </v-btn>
      </currentPayment>
      <paymentMethod
        v-if="!loading && changePaymentMethod"
        @cancel="changePaymentMethod = false"
        @updated="changePaymentMethod = false"
      ></paymentMethod>
    </v-card>
  </v-row>
</template>

<script>
import currentPayment from '@components/paymentForm/currentPayment.vue'
import paymentMethod from '@/components/paymentForm/changePayment.vue'

export default {
  name: 'payment-form',
  components: {
    currentPayment,
    paymentMethod,
  },
  data() {
    return {
      changePaymentMethod: false,
      loading: false,
      headerTitle: 'Forma de Pagamento',
    }
  },
  computed: {
    currentHeaderDescription() {
      if (!this.changePaymentMethod) {
        return `Está precisando alterar sua forma de pagamento ou trocar o número de cartão de crédito cadastrado?
        Não se preocupe! Você pode fazer tudo diretamente pelo Simplificador!`
      }
      return `Escolha uma das formas de pagamento abaixo. Ela será utilizada na sua próxima cobrança.`
    },
  },
  methods: {},
  async mounted() {
    this.loading = true
    await this.$store.dispatch('paymentInfo/getPaymentData')
    this.loading = false
  },
}
</script>
<style scoped>
</style>
