<template>
  <div class="d-flex flex-column justify-center align-center text-center pa-16">
    <v-col cols="4">
      <p>Forma de Pagamento Atual</p>
      <div
        v-if="isBankSlip"
        class="form mb-3 d-flex justify-center align-center text-center"
      >
        <img :src="imgBoleto" />Boleto Bancário
      </div>
      <div v-else class="form d-flex justify-center align-center text-center">
        <v-icon>credit_card</v-icon> Cartão de Crédito
      </div>
    </v-col>
    <slot></slot>
  </div>
</template>

<script>
import imgBoleto from '@src/assets/images/misc/icon-pag-boleto.png'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      imgBoleto,
    }
  },
  computed: {
    ...mapGetters({
      paymentInfo: 'paymentInfo/companyPaymentInfo',
    }),
    isBankSlip() {
      return this.paymentInfo.paymentMethod.name === 'BANK_SLIP'
    },
  },
}
</script>

<style scoped>
.form {
  align-items: center;
  height: 83px;
  border: 1px solid #b9b9b8;
  border-radius: 6px;
}
.form i {
  margin-left: 15px;
  font-size: 4em;
}
.form img {
  width: 70px;
  margin-right: 10px;
}
</style>
